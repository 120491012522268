import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18')
];

export const server_loads = [2];

export const dictionary = {
		"/(app)": [6,[2]],
		"/(bundle)/bundle": [15,[4]],
		"/(app)/classes": [7,[2]],
		"/dev": [17],
		"/(event)/event": [16,[5]],
		"/(app)/final": [8,[2]],
		"/(app)/form/confirmUser": [10,[2,3]],
		"/(app)/form/confirm": [9,[2,3]],
		"/(app)/form/preferences": [11,[2,3]],
		"/(app)/form/registration": [12,[2,3]],
		"/(app)/form/students": [13,[2,3]],
		"/sentry-example": [18],
		"/(app)/signin": [14,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';