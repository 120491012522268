import { dev } from '$app/environment';
import { PUBLIC_SENTRY_MODE } from '$env/static/public';
import * as Sentry from '@sentry/sveltekit';

// If you don't want to use Session Replay, remove the `Replay` integration,
// `replaysSessionSampleRate` and `replaysOnErrorSampleRate` options.
if (!dev) {
	Sentry.init({
		dsn: 'https://745d7cf9aca8cd9b309a39fdd673fd11@o4506003731382272.ingest.sentry.io/4506003777388544',
		tracesSampleRate: 1,
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1,
		integrations: [
			new Sentry.Replay({
				networkDetailAllowUrls: [''],
				networkRequestHeaders: ['X-Custom-Header'],
				networkResponseHeaders: ['X-Custom-Header']
			})
		],
		environment: PUBLIC_SENTRY_MODE
	});
}

export const handleError = Sentry.handleErrorWithSentry();
